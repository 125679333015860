import React from "react";
import { ThemeProvider, createTheme, CssBaseline, responsiveFontSizes } from "@mui/material";

import Header from "./components/header";
import About from "./components/about";
import Services from "./components/services";
import Proposal from "./components/proposal";
import Footer from "./components/footer";

const theme = responsiveFontSizes(
  createTheme({
    spacing: 8,
    typography: {
      fontSize: 20,
      h1: {
        color: "white",
        fontFamily: "Kode Mono", 
        fontSize: 150,
      },
      h2: {
        color: "rgba(255, 255, 255, 0.7)",
      },
      h3: {
        color: "rgba(255, 255, 255, 0.7)",
      },
      h4: {
        color: "rgba(255, 255, 255, 0.7)",
      },
      h5: {
        color: "rgba(255, 255, 255, 0.7)",
      },
      h6: {
        color: "rgba(255, 255, 255, 0.7)",
      },
      body1: {
        fontSize: 18,
      },
      body2: {
        fontSize: 15,
      },
      button: {
        fontSize: 15,
      },
    },
    palette: {
      type: "dark",
      primary: {
        main: "#11111f",
      },
      secondary: {
        main: "#78be20",
      },
      text: {
        primary: '#ffffff',
        secondary: 'rgba(255, 255, 255, 0.7)',
        disabled: 'rgba(255, 255, 255, 0.5)',
        hint: 'rgba(255, 255, 255, 0.5)',
      },
      background: {
        default: "#0f0f0f",
        secondary: "#424242"
      },
    },
  })
)


function App () {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Services />
      <About />
      <Proposal />
      <Footer />
    </ThemeProvider>
  );
}
export default App;
