import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import { Element } from 'react-scroll';

import image from "../images/matt.jpg";

export default () =>  (
  <React.Fragment>
    <Element name="about" className="element">
      <Container maxWidth="md" sx={{marginTop: 5, marginBottom: 5}}>
        <Stack spacing={2}>
          <Typography variant="h3">About</Typography>
          <Typography variant="body1">
          I am a seasoned IT professional with over a decade of industry experience, specializing in managing websites and applications for 
          both small businesses and enterprises. I have a comprehensive understanding of the intricacies involved in managing online platforms. 
          My expertise lies in streamlining processes, optimizing infrastructure, and implementing best practices to drive efficiency and support 
          business growth. Let me leverage my skills to enhance your online presence and propel your business forward.
          </Typography>

          <Box component="section">
            <Typography variant="h6">Matt Robinson</Typography>
            <Typography variant="body1">Founder</Typography>
            <img src={image} width={300} />
          </Box>
        </Stack>
      </Container>
    </Element>
  </React.Fragment>
)
