import React from 'react';
import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack'; 
import Typography from "@mui/material/Typography";
import SecurityIcon from '@mui/icons-material/Security';
import ConstructionIcon from '@mui/icons-material/Construction';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { Element } from 'react-scroll';

export default () => (
  <React.Fragment>
    <Element name="services" className="element">
      <Box 
        sx={{
          flexGrow: 1,
          backgroundColor: "background.secondary",
          backgroundSize: "cover",
          webkitBackgroundSize: "cover",
          // height: "800px",
          width: "100%",
          paddingTop: 5, 
          paddingBottom: 5,
        }}
        layout={{
          verticalAlign: "bottom",
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ px: 2 }}>
            <Stack spacing={2}>
              <Typography variant="h6">What We Do</Typography>
              <Typography variant="h4">
                We provide managed services for businesses and individuals
              </Typography>
              <Box>
                <Grid container spacing={2} columns={{ xs: 6, md: 12 }}>
                  <Grid item xs={6} >
                    <Typography variant="h5"><ConstructionIcon /> Maintenance</Typography>
                    <Typography variant="body1" component="p">
                      Keep your website running smoothly with our comprehensive maintenance services. We'll handle all updates, backups, and optimizations to 
                      ensure your site stays in peak condition. From content updates to plugin management, we've got you covered, so you can focus on your business 
                      while we take care of the technical details.
                    </Typography>
                  </Grid>
                  <Grid item xs={6} >
                    <Typography variant="h5"><SecurityIcon /> Security</Typography>
                    <Typography variant="body1" component="p">
                      Protect your website from threats and hackers with our robust security solutions. We employ advanced security measures to safeguard your 
                      site against malware, viruses, and cyber attacks. With regular security audits and proactive monitoring, you can have peace of mind knowing 
                      your online presence is secure and your data is protected.
                    </Typography>
                  </Grid>
                  <Grid item xs={6} >
                    <Typography variant="h5"><TimelapseIcon /> Uptime</Typography>
                    <Typography variant="body1" component="p">
                      Maximize your website's uptime and minimize downtime with our reliable hosting and monitoring services. We guarantee excellent uptime 
                      performance, ensuring that your site is always accessible to your customers. Our proactive monitoring system detects and resolves issues 
                      swiftly, so you can maintain uninterrupted service and keep your visitors satisfied.
                    </Typography>
                  </Grid>
                  <Grid item xs={6} >
                    <Typography variant="h5"><AnalyticsIcon /> Website Analytics</Typography>
                    <Typography variant="body1" component="p">
                      Gain valuable insights into your website's performance and audience behavior with our comprehensive analytics solutions. Track key metrics 
                      such as traffic, engagement, and conversions to understand how users interact with your site. With actionable data at your fingertips, you 
                      can make informed decisions to optimize your online presence and drive business growth.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>
    </Element>
  </React.Fragment>
)
