import React from "react";
import AppBar  from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Link, animateScroll as scroll } from 'react-scroll';

import image from "../images/header-bg.jpg";

export default function Header () {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  return (
    <React.Fragment>
      <AppBar 
        sx={{
          background: trigger ? "" : "transparent",
          boxShadow: trigger ? "" : "none",
          alignItems: "center",
          height: "60px",
          transition: "0.3s ease-in",
        }}
      >
        <Toolbar>
          <Button color="inherit" onClick={() => scroll.scrollToTop()}>
            <Typography variant="button" component="div" sx={{ flexGrow: 1, color: "secondary.main" }}>Home</Typography>
          </Button>
          <Button color="inherit">
            <Link to="services" smooth={true} offset={-40}>
              <Typography variant="button" component="div" sx={{ flexGrow: 1 }}>Services</Typography> 
            </Link>
          </Button>
          <Button color="inherit">
            <Link to="about" smooth={true} offset={-40}>
              <Typography variant="button" component="div" sx={{ flexGrow: 1 }}>About</Typography> 
            </Link>
          </Button>
          <Button color="inherit">
            <Link to="contact" smooth={true} offset={-40}>
              <Typography variant="button" component="div" sx={{ flexGrow: 1 }}>Contact</Typography>
            </Link>
          </Button>
        </Toolbar>
      </AppBar >
      <Box 
        sx={{
          flexGrow: 1,
          // position: "relative",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          webkitBackgroundSize: "cover",
          // backgroundPosition: "center",
          height: "800px",
          width: "100%",
          textAlign: "center",
          overflow: "hidden",
        }}
        layout={{
          verticalAlign: "bottom",
        }}
      >
        <Grid container direction="column" justifyContent="space-evenly" alignItems="center" spacing={2} sx={{ flexGrow: 1, height: "100%" }}>
          <Grid item xs />
          <Grid item xs={2} >
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="65 146 420 107">
              <path 
                d="M286.13917,146.35221l-27.09946,97.53576l4.87679,1.35502l27.09946,-97.53576zM228.6026,154.73264l-3.79615,-3.58526l-44.71022,44.71022l44.71022,44.71022l3.79615,-3.79615l-41.12496,-40.91407zM321.3974,236.77166l3.79615,3.79615l44.71022,-44.71022l-44.71022,-44.71022l-3.79615,3.58526l41.12496,41.12496z" 
                dataPaperData={{"isPathIcon":true}} 
                style={{fill: "rgb(114, 181, 30)"}} 
              />
            </svg>
            <Typography variant="h1">CHAOSBYTE</Typography>
          </Grid>
          <Grid item xs />
        </Grid>
      </Box>
    </React.Fragment>
  );
}
