import React from 'react';
import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";

import image from "../images/proposal-bg.jpg";

export default () => (
  <React.Fragment>
    <Box 
      sx={{
        flexGrow: 1,
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        webkitBackgroundSize: "cover",
        // height: "800px",
        width: "100%",
        paddingTop: 5, 
        paddingBottom: 5,
      }}
      layout={{verticalAlign: "bottom"}}
    >
      <Container maxWidth="lg">
        <Stack spacing={2}>
          <Typography variant="h4">
            Do you have an idea or an epic project in mind? Let’s work together.
          </Typography>
          <Typography variant="body1" component="p">
            I am here to help you keep your business running the way you want. 
            If you need something not listed on our services page, please get in touch and we can discuss your requirements.
          </Typography>
        </Stack>
      </Container>
    </Box>
  </React.Fragment>
)
