import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import { Element } from 'react-scroll';

export default () => (
  <React.Fragment>
    <footer>
      <Element name="contact" className="element">
        <Box sx={{ px: 2 }}>
          <Container maxWidth="md" sx={{marginTop: 3, marginBottom: 5}}>
            <Stack spacing={0.5}>
              <Typography variant="h5">Contact Us</Typography>
              <Typography variant="body2" component="a" sx={{color: "white"}} href="mailto: mail@chaosbyte.uk">mail@chaosbyte.uk</Typography>
              <Typography variant="body2" component="a" sx={{color: "white"}} href="tel:07362432061">07362 432061</Typography>
            </Stack>
          </Container>
        </Box>
      </Element>
    </footer> 
  </React.Fragment>
)
